import React, { useEffect, useState } from "react"
import { graphql, navigate, PageProps } from "gatsby"
import LayoutContainer from "@containers/Layout"
import SEO from "@components/SEO"
import Tabs from "@components/UI/Tabs"
import { PrivacyPolicyDataType } from "@typings/requests/privacy-policy"

import "./terms-and-conditions.scss"

const PrivacyPolicy: React.FC<PageProps<PrivacyPolicyDataType>> = props => {
  const { strapiPrivacyPolicyPage: privacyPolicyData } = props.data
  const { hash, pathname } = props.location
  const { statements, header, title, metaImage, description } =
    privacyPolicyData

  const [activeTab, setActiveTab] = useState("")
  const toggleTags = () => {
    navigate(`#${statements[0].title.toLocaleLowerCase()}`)
    return statements[0].name
  }

  const getLanguage = () => {
    const browserLanguage = window.navigator.language
    const browserLocale =
      browserLanguage
        .substring(0, browserLanguage.indexOf("-"))
        .toLowerCase() || browserLanguage.toLowerCase()
    const currentLanguage = statements.filter(item =>
      item.name.toLowerCase().includes(browserLocale)
    )
    setActiveTab(currentLanguage.length ? currentLanguage[0]?.name : toggleTags)
  }

  useEffect(() => {
    if (hash) {
      const tagName = hash.substring(1)
      const currentTab = statements.filter(item =>
        item.title.toLocaleLowerCase().includes(tagName)
      )
      setActiveTab(currentTab[0]?.name)
    } else {
      getLanguage()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SEO
        title={title}
        description={description}
        image={metaImage.localFile.publicURL}
        pathname={pathname}
      />
      <LayoutContainer
        className="terms"
        location={props.location}
        colorLangSwitcher="black"
        hideLangSwitcher={true}
      >
        <section className="container terms__header">
          <h1 className="terms__title container__item text text--h1">
            {header}
          </h1>
        </section>
        <section className="container terms__tabs">
          <div className="container__item">
            <Tabs
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabs={statements}
            />
          </div>
        </section>
      </LayoutContainer>
    </>
  )
}

export const query = graphql`
  {
    strapiPrivacyPolicyPage {
      title
      header
      description
      metaImage {
        localFile {
          publicURL
        }
      }
      statements {
        name
        text {
          data {
            text
          }
        }
        title
        id
      }
    }
  }
`

export default PrivacyPolicy
