import React from "react"
import cn from "classnames"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Skeleton from "@components/UI/Skeleton"
import { sanitize } from "@helpers/helpers"
import { AgreementDataType } from "@typings/index"

import "./tabs.scss"

interface TabsPropsTypes {
  activeTab: string
  setActiveTab: (name: string) => void
  tabs: AgreementDataType[]
}

const Tabs: React.FC<TabsPropsTypes> = ({ activeTab, setActiveTab, tabs }) => {
  return (
    <section className="tabs">
      <nav className="tabs__nav tabs-nav">
        <ul className="tabs-nav__list list-unstyled">
          {tabs.map(item => (
            <li className="tabs-nav__item" key={item.name + item.id}>
              <AnchorLink
                to={`#${item.title.toLocaleLowerCase()}`}
                title={item.title}
                className={cn(
                  "button button--small button--black button--rounded",
                  {
                    "button--active": activeTab === item.name,
                  }
                )}
                onAnchorLinkClick={() => setActiveTab(item.name)}
              />
            </li>
          ))}
        </ul>
      </nav>
      {!activeTab?.length ? (
        <Skeleton />
      ) : (
        <div className="tabs__body">
          {tabs.map(
            item =>
              item.name === activeTab && (
                <div
                  key={"content_" + item.id}
                  dangerouslySetInnerHTML={{
                    __html: sanitize(item.text.data.text),
                  }}
                />
              )
          )}
        </div>
      )}
    </section>
  )
}
export default Tabs

Tabs.defaultProps = {
  tabs: [],
}
