import React from "react"
import ContentLoader from "react-content-loader"
import "./skeleton.scss"

const Skeleton = () => {
  return (
    <ContentLoader
      className="skeleton"
      speed={1}
      backgroundColor="#c4c4c4"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="-1" rx="4" ry="4" width="365" height="40" />
      <rect x="0" y="72" rx="4" ry="4" width="563" height="13" />
      <rect x="0" y="94" rx="4" ry="4" width="564" height="13" />
      <rect x="0" y="126" rx="4" ry="4" width="450" height="13" />
      <rect x="0" y="151" rx="4" ry="4" width="528" height="13" />
      <rect x="0" y="174" rx="4" ry="4" width="289" height="13" />
      <rect x="0" y="220" rx="4" ry="4" width="446" height="13" />
    </ContentLoader>
  )
}

export default Skeleton
